<template>
  <div id="mian" class="container">
    <ContainerList
      ref="container"
      :page-size="filterData.pageSize"
      :page-current.sync="filterData.pageNo"
      :page-count="totalNum"
      @page-change="changePageNo"
      @page-size-change="changePageSize"
    >
      <div slot="header">
        <div class="con_from">
          <div class="lineP">
            <div class="item">
              <i>商户名称</i>
              <Input
                v-model="filterData.keyword"
                suffix="ios-search"
                size="large"
                maxlength="128"
                placeholder="商户名称/编号"
              />
            </div>
            <div class="item">
              <i>审核状态</i>
              <Select v-model="filterData.checkStatus" size="large">
                <Option
                  v-for="item in checkStatusOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="item">
              <i>激活状态</i>
              <Select v-model="filterData.activateStatus" size="large">
                <Option
                  v-for="item in activateStatusOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="lineP">
            <div class="item date-item">
              <i>创建时间</i>
              <DatePicker
                v-model="filterData.startTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                :clearable="true"
                :editable="false"
                :options="startDatePicker"
                placeholder="选择时间"
                style="
                  width: calc((100% - 100px - 24.67px - 10px) / 2) !important;
                "
              >
              </DatePicker>
              <span style="padding-left: 10px; padding-right: 10px">-</span>
              <DatePicker
                v-model="filterData.endTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                :clearable="true"
                :editable="false"
                :options="endDatePicker"
                placeholder="选择时间"
                style="
                  width: calc((100% - 100px - 24.67px - 10px) / 2) !important;
                "
                @on-change="endTimeChange"
              >
              </DatePicker>
            </div>
            <div class="itembtn">
              <Button type="primary" class="search-btn" @click="getDataByFilter"
                >查询</Button
              >
              <Button
                v-if="activateStatus !== 'FROZEN'"
                class="add-btn"
                v-auth="'BASIC:FILEMANA:BUSINESSLIST/ADD'"
                @click="addModalShow = true"
                style="margin-left: 20px"
                >新增</Button
              >
            </div>
          </div>
        </div>
      </div>
      <Table
        row-key
        width="1000px"
        class="table"
        :columns="tableColumns"
        :data="tableData"
        :loading="isLoading"
      >
        <template slot="mchModel" slot-scope="{ row }">
          {{ mchModelMap[+row.mchModel] }}
        </template>
        <template slot="format" slot-scope="{ row, column }">
          <span v-if="column.key === 'checkStatus'">{{
            row[column.key] | filterStatus(checkStatusOptions)
          }}</span>
          <span v-if="column.key === 'activateStatus'">{{
            row[column.key] | filterStatus(activateStatusOptions)
          }}</span>
        </template>
        <template slot="action" slot-scope="{ row }">
          <a
            v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL'"
            @click="toDetail(row)"
            >详情</a
          >
        </template>
      </Table>
    </ContainerList>
    <Modal
      v-model="addModalShow"
      title="新增商户"
      closable
      width="490px"
      @on-cancel="addShow = false"
    >
      <RadioGroup v-model="businessType" vertical>
        <Radio class="modal-radio" :label="1" size="small">
          <span :style="{ color: businessType == 1 ? '#409EFF' : '#606266' }">
            通过服务商API入网
          </span>
        </Radio>
        <Radio class="modal-radio" :label="2" size="small">
          <span :style="{ color: businessType == 2 ? '#409EFF' : '#606266' }">
            已有电子账簿商户入网
          </span>
        </Radio>
      </RadioGroup>
      <span slot="footer" class="dialog-footer">
        <LevelButton
          btnTitle="确 定"
          size="middle"
          @confirm="handleClickAdd"
          @close="addUserShow = false"
        ></LevelButton>
      </span>
    </Modal>
  </div>
</template>

<script>
import ContainerList from "@/components/ContainerList/index";
import tableMixin from "@/mixins/tableMixin";
import { merchantsList } from "@/api/basic/fileManage.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    return {
      activateStatus: "",
      filterData: {
        activateStatus: -1,
        checkStatus: -1,
        keyword: "",
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
      },

      startDatePicker: this.startDate(),
      endDatePicker: this.endData(),

      totalNum: 0,
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
      tableLoading: false,
      tableApi: merchantsList,
      tableKey: "merchants",
      tableColumns: [
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          minWidth: 170,
        },
        {
          title: "商户名称",
          key: "mchName",
          align: "center",
          minWidth: 210,
        },
        {
          title: "商户编号",
          key: "mchId",
          align: "center",
          minWidth: 210,
        },
        {
          title: "商户来源",
          key: "mchModel",
          align: "center",
          slot: "mchModel",
          minWidth: 90,
        },
        {
          title: "审核状态",
          key: "checkStatus",
          align: "center",
          slot: "format",
          minWidth: 90,
        },
        {
          title: "激活状态",
          key: "activateStatus",
          align: "center",
          slot: "format",
          minWidth: 90,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          slot: "action",
          minWidth: 90,
        },
      ],
      tableData: [],
      // 筛选项
      checkStatusOptions: [
        { label: "全部", value: -1 },
        { label: "待审核", value: "WAIT" },
        { label: "已通过", value: "PASS" },
        { label: "已驳回", value: "REJECT" },
      ],
      activateStatusOptions: [
        { label: "全部", value: -1 },
        { label: "未激活", value: "NOTACTIVATE" },
        { label: "已激活", value: "ACTIVATE" },
        { label: "已冻结", value: "FROZEN" },
        { label: "已关闭", value: "CLOSED" },
      ],

      // 新增商户
      addModalShow: false,
      businessType: 1,
      mchModelMap: {
        1: "服务商API入网",
        3: "已有电子账簿商户入网",
      },
    };
  },
  computed: {
    ...mapState({
      businessListQuery: "file_business",
    }),
  },
  filters: {
    filterStatus(val, options) {
      let result = {};
      options.forEach((item) => {
        result[item.value] = item.label;
      });
      return result[val];
    },
  },
  created() {
    if (this.businessListQuery) {
      this.filterData = this.businessListQuery;
    }
    this.getDataByFilter();

    const { activateStatus } = JSON.parse(sessionStorage.getItem("userInfo"));
    this.activateStatus = activateStatus;
  },
  destroyed() {
    this.setBusinessListQuery(this.filterData);
  },
  methods: {
    ...mapMutations({
      setBusinessData: "set_business_data",
      setBusinessListQuery: "file_setBusiness",
    }),
    // 请求前钩子
    beforeRequest(params) {
      if (params.startTime) {
        params.startTime = formatDate(params.startTime, "yyyy-MM-dd hh:mm:ss");
      }
      if (params.endTime) {
        params.endTime = formatDate(params.endTime, "yyyy-MM-dd hh:mm:ss");
      }
      return params;
    },
    afterResponse(list, data) {
      this.totalNum = data.totalNum || 0;
      return list;
    },
    //创建时间改变时
    endTimeChange(time, type) {
      if (type == "date" && time.slice(-8) === "00:00:00") {
        this.filterData.endTime = time.slice(0, -8) + "23:59:59";
      }
    },
    dateChange(date) {
      if (+new Date(date[0]) > +new Date(date[1])) {
        return this.$Message.error("开始日期不能大于结束日期");
      }
      if (date && date[0] && date[1]) {
        if (!this.timeRange[0] && !this.timeRange[1]) {
          this.timeRange = ["00:00:00", "23:59:59"];
        }
      }
    },
    startDate() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.endTime
            ? !(
                new Date(vm.filterData.endTime).getTime() >= time.getTime() &&
                time.getTime() >
                  new Date(vm.filterData.endTime).getTime() -
                    31 * 24 * 60 * 60 * 1000
              )
            : false;
        },
      };
    },
    endData() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.startTime
            ? !(
                new Date(vm.filterData.startTime).getTime() <= time.getTime() &&
                time.getTime() <
                  new Date(vm.filterData.startTime).getTime() +
                    31 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
    //创建时间改变时
    endTimeChange(time, type) {
      if (type == "date" && time.slice(-8) === "00:00:00") {
        this.filterData.endTime = time.slice(0, -8) + "23:59:59";
      }
    },
    toDetail(row) {
      if (row.mchModel == 1) {
        this.$router.push({
          name: "businessDetail",
          query: {
            id: row.mchId,
          },
        });
        this.setBusinessData(null);
      }
      if (row.mchModel == 3) {
        this.$router.push({
          name: "addMerchantDetail",
          query: {
            id: row.mchId,
          },
        });
        this.setBusinessData(null);
      }
    },
    handleClickAdd() {
      if (this.businessType == 1) {
        this.$router.push({
          name: "addBusiness",
          query: {
            type: "add",
          },
        });
        this.setBusinessData(null);
      }
      if (this.businessType == 2) {
        this.$router.push({
          name: "addMerchant",
          query: {
            type: "add",
          },
        });
        this.setBusinessData(null);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.con_from .lineP .date-item {
  width: 40%;
}
.container {
  background: #fff;
  .table {
    min-height: 700px;
  }
  .date-picker {
  }
  .time-picker {
    margin-left: -6px;
  }
  .dateline {
    margin-left: 10px;
    margin-right: 10px;
  }
  .add-btn,
  .search-btn {
    width: 90px;
  }
}
.modal-radio {
  font-size: 15px;
  margin: 0 auto;
}
// /deep/ .ivu-icon-ios-calendar-outline:before {
//   content: '';
// }
/deep/ .ivu-date-picker-focused input:not([disabled]) {
  border-color: transparent;
  box-shadow: none;
}
/deep/ .ivu-modal-body {
  display: flex;
}
/deep/ .ivu-radio-group-vertical {
  margin: 0 auto;
}
</style>
